// extracted by mini-css-extract-plugin
export var featuredBuildBody = "featuredBuild-module--featuredBuildBody--h7Wzf";
export var featuredBuildBreadcrumbs = "featuredBuild-module--featuredBuildBreadcrumbs--xi2Ek";
export var featuredBuildColumns = "featuredBuild-module--featuredBuildColumns--0pp65";
export var featuredBuildColumnsCarousel = "featuredBuild-module--featuredBuildColumnsCarousel--irJm3";
export var featuredBuildColumnsCarouselSlide = "featuredBuild-module--featuredBuildColumnsCarouselSlide--xJdSO";
export var featuredBuildColumnsCarouselSlideImage = "featuredBuild-module--featuredBuildColumnsCarouselSlideImage--YZWAe";
export var featuredBuildColumnsCarouselSlideVideo = "featuredBuild-module--featuredBuildColumnsCarouselSlideVideo--dX4jw";
export var featuredBuildColumnsSpecifications = "featuredBuild-module--featuredBuildColumnsSpecifications--BNfri";
export var featuredBuildColumnsSpecificationsFootnote = "featuredBuild-module--featuredBuildColumnsSpecificationsFootnote--2wAvD";
export var featuredBuildHeading = "featuredBuild-module--featuredBuildHeading--Vm9wa";
export var featuredBuildInner = "featuredBuild-module--featuredBuildInner--tw6vD";