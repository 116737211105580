import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Base from '@webLayouts/Base/Base';
import FeaturedBuild from '@webPages/FeaturedBuilds/FeaturedBuild';
import { FeaturedQuery } from '@generated/GraphqlTypes';
import decodeFeaturedDetailed from '@decoders/FeaturedDetailed';

const FeaturedBuildPage: React.FC<PageProps<FeaturedQuery>> = ({ data }) => {
  let seoTitle = data?.markdownRemark?.frontmatter?.seoTitle ?? '';

  if (!seoTitle.includes('|')) {
    const seoTitlePostFix = ' | Custom Modular Homes | Ecoliv';
    seoTitle = `${seoTitle}${seoTitlePostFix}`;
  }
  const seoDescription =
    data?.markdownRemark?.frontmatter?.seoDescription ||
    data?.markdownRemark?.excerpt ||
    undefined;

  const featured = decodeFeaturedDetailed(data);
  const image = data.markdownRemark?.frontmatter?.hero?.publicURL || undefined;

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <FeaturedBuild featured={featured} />
    </Base>
  );
};

export default FeaturedBuildPage;

export const query = graphql`
  query Featured($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        seoTitle
        seoDescription
        title
        traditionalName
        video
        tags
        price
        features {
          modules
          area
          deck
        }
        hero {
          publicURL
        }
        images {
          publicURL
        }
      }
      excerpt
      html
    }
  }
`;
